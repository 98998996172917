import React from 'react';
import {observer} from 'mobx-react';
import {IBoard, INode} from '../../../types/types';
import BoardNode from './BoardNode';
import {groupNodeRegExp} from '../../../utils/StyleguideUtils';
import NodeUtils from '../../../utils/NodeUtils';

interface IListItemProps {
    boardNode: IBoard;
    node: INode;
    children?: never;
}

/**
 * Компонент для отрисовки элемента списка.
 */
const ListItem: React.FC<IListItemProps> = observer(({boardNode, node}) => {
    const match = node.content.name.match(groupNodeRegExp);

    return (
        <div className="list-item" id={node.nodeFigmaId}>
            {NodeUtils.getChildrenNodes(boardNode, node).map((k) => (
                <BoardNode key={k.id} boardNode={boardNode} node={k} />
            ))}
        </div>
    );
});

export default ListItem;
